import Rails from '@rails/ujs';

Rails.start();

import * as Sentry from "@sentry/browser";

if (window.location.host === 'my.ancestr.al') {
  Sentry.init({
    dsn: "https://1e4dd9d67892962c9d2c701d5626db6a@o4506375964590080.ingest.sentry.io/4506375975272448",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
